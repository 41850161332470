import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, A11y } from "swiper";
import { initNeosEventItemCreated } from "../../Assets/Scripts/neosEvents";
import { initNeosEventItemSelected } from "../../Assets/Scripts/neosEvents";

export function mediaSwiper() {
    document.querySelectorAll(".media--swiper").forEach((slider) => {
        const isNeosBackend = document.body.classList.contains('neos-backend')

        const swiperItems = Array.from(slider.querySelectorAll('.swiper-slide'))
        if(swiperItems.length <= 1 && !isNeosBackend) {
            return
        }

        const swiper = new Swiper(slider, {
            modules: [Navigation, Pagination, Autoplay, A11y],
            allowTouchMove: !isNeosBackend,
            slidesPerView: 1,
            spaceBetween: 0,
            watchOverflow: true,
            resizeObserver: true,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-navigation-next",
                prevEl: ".swiper-navigation-prev",
            },
        });

        if (slider.hasAttribute("data-autoplay") && !isNeosBackend) {
            new IntersectionObserver((entries, observer) => {
                if (entries[0].isIntersecting) {
                    observer.disconnect();
                    swiper.params.autoplay = {
                        delay: 5000,
                    };
                    swiper.autoplay.start();
                }
            }).observe(slider);
        }

        initNeosEventItemCreated(swiper, slider);
        initNeosEventItemSelected(swiper, slider);
    });
}

mediaSwiper()
