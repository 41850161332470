document.body.addEventListener('click', (event) => {
    if (event.target.closest('.accordion--item button')) {
        let item = event.target.closest('.accordion--item');

        item.classList.toggle('active');

        // Close other Accordion-Items in same Accordion-Container
        item.closest('.accordion').querySelectorAll('.accordion--item').forEach(otherItem => {
            if (otherItem !== item) {
                otherItem.classList.remove('active');
            }
        });
    }
});

document.querySelectorAll('.accordion--firstElement--open').forEach(accordion => {
    accordion .querySelector('.accordion--item').classList.add('active');
})